import View from '../view';
import debounce from 'debounce';

class FacebookPageFeed extends View {

	constructor(el){
    super(el);
    this.el = el;
    this.FBready = false;

    // Listen to render event to make sure we can manipulate it
    window.addEventListener('resize', debounce(() => this.handleResize(), 300));

    window.fbAsyncInit = () => {
      FB.Event.subscribe('xfbml.render', () => {
        this.FBready = true;
        if (!this.fbPageEl) this.fbPageEl = el.querySelector('.fb-page');
        if (!this.iFrameWrapper) this.iFrameWrapper = this.fbPageEl.firstElementChild;
        if (!this.iframe) this.iframe = el.querySelector('iframe');
        this.handleResize();
      });
    }
  }

  handleResize() {
    if (!this.FBready) return;
    const w = this.el.offsetWidth;
    if (w === this.currentWidth) return;
    this.currentWidth = w;

    this.fbPageEl.setAttribute('data-width', w);
    this.fbPageEl.setAttribute('data-height', w);
    this.iframe.style.width = `${w}px`;
    this.iframe.style.height = `${w}px`;
    this.iFrameWrapper.style.width = `${w}px`;
    this.iFrameWrapper.style.height = `${w}px`;
    if (FB) FB.XFBML.parse();
  }

}

export default FacebookPageFeed;
