
import TimelineLite from 'gsap/umd/TimelineLite';
import TweenLite from 'gsap/umd/TweenLite';
import Power3 from 'gsap/umd/EasePack';
import ScrollMagic from 'scrollmagic';

class AnimatedReveal {

	constructor() {
		this.scrollMagicController = new ScrollMagic.Controller();
		this.scenes = [];

		this.defaultProps = {
			distance: 30
		};

		let animatedElements = document.querySelectorAll('.js--animated-reveal');

		let wh = window.innerHeight
		|| document.documentElement.clientHeight
		|| document.body.clientHeight;

		[...animatedElements].map((elem)=>{
			let scene = new ScrollMagic.Scene({triggerElement: elem, offset:-(wh/5)});
				scene.addTo(this.scrollMagicController);
				scene.on("enter", this.elementInView.bind(this));

			this.scenes.push(scene);
		});
	}

	elementInView(event) {
		let element = event.target.triggerElement();
		let dataset = element.dataset;

		if ( !element.classList.contains('is-animated') ) {
			element.classList.add('is-animated');

			if (dataset.animtype == 'stagger' && dataset.animtarget) {
				let children = element.querySelectorAll('.'+dataset.animtarget);
				let tl = new TimelineLite();
				tl.staggerFromTo(children, 0.4, {y:-10,opacity:0},{y:0,opacity:1}, 0.1, "stagger");
			}
			else {
				let toProps = {
					delay:dataset.animdelay ? dataset.animdelay : 0,
					ease:dataset.animease ? window[dataset.animease].easeOut : Power3.easeOut
				};
				let fromProps = {
					delay:dataset.animdelay ? dataset.animdelay : 0,
					ease:dataset.animease ? window[dataset.animease].easeOut : Power3.easeOut
				};

				switch (dataset.animtype){
					case 'up':
						fromProps.opacity = 1;
						toProps.opacity = 1;
						fromProps.y = this.defaultProps.distance;
						break;
					case 'fade':
						toProps.opacity = 1;
						break;
					case 'fadedown':
						toProps.opacity = 1;
						fromProps.y = -this.defaultProps.distance;
						break;
					case 'fadeup':
						toProps.opacity = 1;
						fromProps.y = this.defaultProps.distance;
						break;
					case 'faderight':
						toProps.opacity = 1;
						fromProps.x = this.defaultProps.distance;
						break;
					case 'fadeleft':
						toProps.opacity = 1;
						fromProps.x = -this.defaultProps.distance;
						break;
					case 'fadescale':
						toProps.opacity = 1;
						fromProps.scale = 0.6;
						break;
				}
				let duration = dataset.animduration ? dataset.animduration : 2;

				TweenLite.to(element,duration,toProps);
				TweenLite.from(element,duration,fromProps);
			}
		}
	}
}


export default new AnimatedReveal();
