import ES6EventEmitter from "es6-event-emitter";

class Emitter extends ES6EventEmitter {
	constructor() {
		super();
		this.addListener = this.addListener.bind(this);
		this.removeListner = this.removeListner.bind(this);
	}
	addListener(evnet, handler) {
		this.on(event, handler);
	}
	removeListner(evnet, handler) {
		this.off(event, handler);
	}
}

// export singleton
export default new Emitter();
