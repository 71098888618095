class SubscribePanel{
	constructor(el) {
		this.el = el;

		this.closeButton = this.el.querySelector('.js--close');
		this.closeButton.addEventListener('click', this.close.bind(this));
	}

	close() {
		this.el.classList.remove('is-active');
	}
}

export default SubscribePanel;
