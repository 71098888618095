import stickybits from 'stickybits';
import debounce from 'debounce';

class Stickybits {

	constructor(el){
		this.el = el;

		this.stickybitsInstance = stickybits('.js--is-sticky', {
			useStickyClasses: true,
			stickyClass:'is-sticky',
			stuckClass:'is-stuck',
			stickyChangeClass:'is-sticky-change',
			parentClass:'js--sticky-parent'
		});

		addEventListener('stickybits:update', () => this.stickybitsInstance.update());
		addEventListener('resize', debounce(() => this.stickybitsInstance.update(), 300));
	}
}


export default Stickybits;
