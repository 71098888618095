import Emitter from "./components/emitter";
export default class {
	constructor(element, options = {}) {
		this.options = element.options || options;
		this.el =
			element instanceof HTMLElement
				? element
				: element.el || document.createElement("span");
		this.el.classList.add("is-bound");
	}
	static get eventEmitter() {
		return Emitter;
	}
}
