import Swiper from 'swiper/dist/js/swiper.js';

class Slider {
	constructor(el) {
		this.el = el;

		this.container = this.el.querySelector('.js--container');
		this.nextButton = this.el.querySelector('.js--next-slide');
		this.previousButton = this.el.querySelector('.js--previous-slide');
		this.pagination = this.el.querySelector('.js--pagination');

		this.loop = this.el.dataset.loop;
		this.autoplay = this.el.dataset.autoplay;

		this.properties = {
			wrapperClass: 'swiper-wrapper',
			slideClass: 'swiper-slide',
			navigation: {
				nextEl: this.nextButton,
				prevEl: this.previousButton
			},
			spaceBetween: 60,
			pagination: {
				el: this.pagination
			}
		};
		if ( this.autoplay ) this.properties.autoplay = {delay: 5000,disableOnInteraction:false};
		if ( this.loop ) this.properties.loop = true;

		let swiper = new Swiper(this.container, this.properties);

	}
}

export default Slider;
