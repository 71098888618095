import Emitter from './emitter';

class SearchFiltersOverlay {
	constructor(el) {
		this.el = el;

		this.bodyRef = document.querySelector('body');
		this.htmlRef = document.querySelector('html');

		this.triggerRef = document.querySelector('.js--show-filters-overlay');

		this.headline = this.el.querySelector('.js--headline');

		this.content = this.el.querySelector('.js--content');
		this.contentInner = this.content.querySelector('.js--content-inner');

		this.clearButton = this.el.querySelector('.js--clear');
		this.closeButton = this.el.querySelector('.js--close');
		this.submitButton = this.el.querySelector('.js--submit');
		this.backButton = this.el.querySelector('.js--navigate-back');

		this.lists = this.el.querySelectorAll('.js--criterion-list');
		this.categoryButtons = this.el.querySelectorAll('.js--category');
		this.categoryButtonLabels = this.el.querySelectorAll('.js--category p');



		this.listData = {};

		this.triggerRef.addEventListener('click', this.openHandler.bind(this) );
		this.clearButton.addEventListener('click', this.clearHandler.bind(this) );
		this.closeButton.addEventListener('click', this.closeHandler.bind(this) );
		this.submitButton.addEventListener('click', this.closeHandler.bind(this) );
		this.backButton.addEventListener('click', this.backHandler.bind(this) );

		[...this.categoryButtons].map((categoryButton)=> {
			categoryButton.addEventListener('click', this.categoryHandler.bind(this) );
		});

		this.currentListId = -1;
		this.currentList = null;

		Emitter.on('criterionlist:actives', this.listActivesHandler.bind(this));
		Emitter.on('criterionlist:empty', this.listEmptyHandler.bind(this));
		Emitter.on('criterionlist:init', this.listInitHandler.bind(this));

	}

	checkForEmptyLists() {
		var foundNonEmpty = false;
		for (var key in this.listData){
			if ( this.listData[key] != false ) {
				foundNonEmpty = true;
				break;
			}
		}
		if ( foundNonEmpty ) {
			this.clearButton.classList.add('is-active');
			this.submitButton.classList.add('is-active');
			this.closeButton.classList.remove('is-active');
		}
		else {
			this.clearButton.classList.remove('is-active');
			this.submitButton.classList.remove('is-active');
			this.closeButton.classList.add('is-active');
		}
	}

	listActivesHandler(instanceId) {
		this.listData["list"+instanceId] = true;

		this.checkForEmptyLists();
	}

	listEmptyHandler(instanceId) {
		this.listData["list"+instanceId] = false;

		this.checkForEmptyLists();
	}

	listInitHandler(instanceId) {
		this.listData["list"+instanceId] = false;
	}

	openHandler(event) {
		this.el.setAttribute('style','display:block;');
		clearTimeout(this.toggleTimeout);

		this.toggleTimeout = setTimeout(function(){
			this.el.classList.add('is-active');
		}.bind(this), 50);
	}

	closeHandler(event) {
		this.el.classList.remove('is-active');

		clearTimeout(this.toggleTimeout);
		this.toggleTimeout = setTimeout(function() {
			this.el.setAttribute('style','display:none;');
		}.bind(this), 200);
	}

	clearHandler(event) {
		Emitter.trigger('criterionlist:clear');
		this.clearButton.classList.remove('is-active');
		this.submitButton.classList.remove('is-active');
		this.closeButton.classList.add('is-active');
		[...this.categoryButtonLabels].map((label) => {
			label.classList.remove('is-active');
		});
	}

	backHandler(event) {
		if ( this.currentListId > -1 ) {
			this.currentList.classList.remove('is-active');
			this.currentListId = -1;
			this.currentList = null;
			this.content.classList.remove('is-navigated');

			this.headline.textContent = this.headline.dataset.default;
		}

		else {
			this.closeHandler();
		}
	}

	categoryHandler(event) {
		let category = event.currentTarget;

		try {
			this.currentListId = category.dataset.listid;
			let headline = category.querySelector('.text').textContent;

			this.currentList = this.contentInner.querySelector('.js--criterion-list[data-listid="'+this.currentListId+'"]');
			[...this.lists].map((list) => {
				list.classList.remove('is-active');
			});

			this.currentList.classList.add('is-active');
			this.content.classList.add('is-navigated');

			this.headline.textContent = headline;
		}
		catch (e) {

		}
	}
}

export default SearchFiltersOverlay;
