class Video {

	constructor(el){
		this.el = el;

		this.play = this.el.querySelector('.js--video-play');
		this.video = this.el.querySelector('video');
		this.image = this.el.querySelector('img');
		if (this.play) this.play.addEventListener('click', () => this.handlePlay());
	}

	handlePlay() {
		console.log(this.play);
		this.play.classList.add('is-hidden');
		this.video.play();
		if (this.image) this.image.classList.add('is-hidden');
	}

}

export default Video;
