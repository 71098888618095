class PageHeaderSearch {
	constructor(el) {
		this.el = el;

		mobileFiltersVisible: false,
			(this.input = this.el.querySelector(
				".js--page-header-search-field"
			));
		this.filters = this.el.querySelector(".js--filters");

		this.selects = this.el.querySelectorAll(".js--filters select");

		this.clearButton = this.el.querySelector(".js--page-header-clear");
		this.showButton = this.el.querySelector(".js--show-filters");

		this.showButton.addEventListener("click", this.showFilters.bind(this));
		this.clearButton.addEventListener("click", this.clearSearch.bind(this));
		this.input.addEventListener("change", this.showClearButton.bind(this));
		this.input.addEventListener("input", this.showClearButton.bind(this));

		[...this.selects].map(select => {
			select.addEventListener("change", this.showClearButton.bind(this));
		});
	}

	showClearButton() {
		this.clearButton.classList.remove("is-hidden");
	}

	clearSearch() {
		this.input.value = "";

		[...this.selects].map(elem => {
			elem.selectedIndex = 0;
		});

		this.clearButton.classList.add("is-hidden");
	}

	showFilters() {
		this.filters.style = "display:block;";
		setTimeout(
			function() {
				this.filters.classList.add("is-active");
			}.bind(this),
			10
		);

		this.mobileFiltersVisible = true;

		this.showButton.classList.add("is-hidden");
	}
}

export default PageHeaderSearch;
