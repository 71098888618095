import Emitter from './emitter';

class SearchLayer{

	constructor(el) {
		this.el = el;

		this.isOverlay = !this.el.classList.contains('search-layer--deck');
		this.isActive = this.el.classList.contains('is-active');

		if ( this.isActive ) {
			this.el.setAttribute('style','display:block;');
		}

		this.bodyRef = document.querySelector('body');
		this.htmlRef = document.querySelector('html');

		this.searchToggleRef = document.querySelector('.js--search-toggle');
		this.input = this.el.querySelector('input');
		this.typeAhead = this.el.querySelector('.js--type-ahead');
		this.searchToggles = this.el.querySelector('.js--toggles');

		this.searchInput = this.el.querySelector('input');

		this.searchToggleRef.addEventListener('click', this.searchToggleHandler.bind(this) );


		this.input.addEventListener('change', this.inputChangeHandler.bind(this) );
		this.input.addEventListener('keyup', this.inputChangeHandler.bind(this) );
		this.input.addEventListener('focus', this.inputFocusHandler.bind(this) );
		this.input.addEventListener('blur', this.inputBlurHandler.bind(this) );
	}

	searchToggleHandler(event) {
		event.stopPropagation();

		if ( this.htmlRef.classList.contains('is-burger-mode') ) {
			this.openSearchLayer();
			Emitter.trigger('search:open');
		}
		else if ( !this.isActive ) {
			this.openSearchLayer();
		}
		else {
			this.closeSearchLayer();
		}
	}

	openSearchLayer() {
		this.isActive = true;

		if ( this.isOverlay ) {
			this.bodyRef.addEventListener('click', this.bodyClickHandler.bind(this));
		}

		this.el.setAttribute('style','display:block;');
		clearTimeout(this.toggleTimeout);
		this.toggleTimeout = setTimeout(function(){
			this.el.classList.add('is-active');
			this.searchInput.focus();
		}.bind(this), 20);

		this.searchToggleRef.setAttribute('aria-expanded','true');
	}

	closeSearchLayer() {
		this.isActive = false;

		if ( this.isOverlay ) {
			this.bodyRef.removeEventListener('click', this.bodyClickHandler.bind(this));
		}
		this.el.classList.remove('is-active');

		clearTimeout(this.toggleTimeout);
		this.toggleTimeout = setTimeout(function() {
			this.el.setAttribute('style','display:none;');
		}.bind(this), 3000);

		if ( this.typeAhead instanceof HTMLElement ) this.closeTypeAhead();

		this.searchToggleRef.setAttribute('aria-expanded','false');
	}

	bodyClickHandler(event) {
		var el = event.target;
		var insideHeader = el.closest('.site-header');

		if ( !insideHeader && this.isOverlay ) {
			this.closeSearchLayer();
		}
		if ( this.typeAhead instanceof HTMLElement ) this.closeTypeAhead();
	}

	inputChangeHandler(event) {
		if ( this.typeAhead instanceof HTMLElement ) this.openTypeAhead();

		var key = event.which || event.keyCode || 0;
		if ( key == 27 ) this.closeTypeAhead();
	}

	inputFocusHandler(event) {
		if ( this.searchToggles instanceof HTMLElement ) {
			this.searchToggles.setAttribute('style','display:flex;');
			setTimeout(function() {
				this.searchToggles.classList.add('is-active');
			}.bind(this), 50);
		}
	}

	inputBlurHandler(event) {

	}

	openTypeAhead() {
		clearTimeout(this.typeAheadTimeout)
		this.typeAheadTimeout = setTimeout(function(){

			this.typeAhead.setAttribute('style','display:block;');
			setTimeout(function() {
				this.typeAhead.classList.add('is-active');

				this.bodyRef.addEventListener('click', this.bodyClickHandler.bind(this));
			}.bind(this), 50);
		}.bind(this), 1000);
	}

	closeTypeAhead() {
		clearTimeout(this.typeAheadTimeout);
		this.typeAhead.classList.remove('is-active');
		setTimeout(function() {
			this.typeAhead.setAttribute('style','display:none;');
		}.bind(this), 200);
	}
}

export default SearchLayer;
