class ToggleButton{

	constructor(el) {
		this.el = el;

		this.el.addEventListener('click', this.toggle.bind(this));
	}

	toggle() {
		this.el.classList.toggle('is-active');
	}
}


export default ToggleButton;
