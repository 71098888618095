import Emitter from "./emitter";
import Utils from "./utils";
import View from "../view";
import { TweenLite } from "gsap";

class Accordion extends View {
	get getInitialOptions() {
		return {
			allowMultiple: true,
			allowToggle: true,
			duration: 0.3
		};
	}
	constructor(el, id = null) {
		super(el);
		// this.el = Utils.elProxy(el);

		this.id = id;
		if (this.el.getAttribute("data-mounted")) return;
		this.options = this.getInitialOptions;
		this.el.setAttribute("data-mounted", true);

		this.open = this.open.bind(this);
		this.close = this.close.bind(this);

		for (let key in this.el.dataset) {
			if (this.options.hasOwnProperty(key)) {
				this.options[key] =
					this.el.dataset[key] === "true" ? true : false;
			}
		}

		this.trigger = this.el.querySelector(".js--accordion-trigger");
		this.internalButtons = this.el.querySelectorAll(".button");

		this.trigger.addEventListener("click", this.onToggle.bind(this));

		[...this.internalButtons].map(button => {
			button.addEventListener(
				"click",
				this.handleInlineButtonClick.bind(this)
			);
		});

		this.savedWindowWidth = 0;

		this.content = this.el.querySelector(".js--accordion-content");

		this.content.setAttribute(
			"aria-hidden",
			this.trigger.getAttribute("aria-expanded") == "false"
		);
		this.content.style.display =
			this.trigger.getAttribute("aria-expanded") == "true"
				? "block"
				: "none";

		Emitter.on("accordion:update", this.updateHeight.bind(this));
		Emitter.on("accordion:close", event => {
			if (this.id === event.id) {
				this.close(this);
			}
		});
		Emitter.on("accordion:open", event => {
			if (this.id === event.id) {
				this.open();
			}
		});
	}

	onToggle(e) {
		e.preventDefault();
		e.stopPropagation();
		const target = e.currentTarget;
		// const content = target.parentElement.nextElementSibling;
		// Check if the current toggle is expanded.
		const isOpen = this.trigger.getAttribute("aria-expanded") == "true";

		if (!isOpen) {
			// to open state
			this.open();

		} else if (
			this.options.allowToggle ||
			(this.options.allowMultiple && isOpen)
		) {
			// to close
			this.close();
		}
	}

	handleInlineButtonClick(event) {
		event.stopPropagation();
	}

	open() {
		this.content.setAttribute("aria-hidden", "false");
		this.el.classList.add("is-active");
		this.content.style.height = "auto";
		this.content.style.display = "block";

		TweenLite.to(this.content, this.options.duration, { opacity: 1 });
		TweenLite.from(this.content, this.options.duration, {
			height: 0,
			onComplete: () => {
				// Set the expanded state on the triggering element
				this.trigger.setAttribute("aria-expanded", "true");

				// If toggling is not allowed, set disabled state on trigger
				if (!this.options.allowToggle) {
					this.trigger.setAttribute("aria-disabled", "true");
				}
			}
		});
		setTimeout(
			function() {
				Emitter.trigger("panel:update");
			}.bind(this),
			500
		);
	}

	close() {
		// Set the expanded state on the triggering element
		this.el.classList.remove("is-active");
		// animate content to closed

		TweenLite.to(this.content, this.options.duration, {
			height: 0,
			opacity: 0,
			onComplete: () => {
				this.trigger.setAttribute("aria-expanded", "false");
				// Hide the accordion sections, using aria-controls to specify the desired section
				this.content.setAttribute("aria-hidden", "true");
				this.content.style.display = "none";
				// When toggling is not allowed, clean up disabled state
				if (!this.options.allowToggle) {
					this.trigger.removeAttribute("aria-disabled");
				}
				setTimeout(
					function() {
						Emitter.trigger("panel:update");
					}.bind(this),
					500
				);
			}
		});
	}

	resizeHandler(event) {
		var width = window.outerWidth;
		if (this.savedWindowWidth === width) return false;
		else {
			this.savedWindowWidth = width;
			this.updateHeight();
		}
	}

	updateHeight(event) {
		// if ( this.active ) {
		// 	this.content.style.maxHeight = 'none';
		// 	this.maxHeight = this.content.offsetHeight;
		// }
		// else {
		// 	this.content.style.maxHeight = '0px';
		// }
		return;
	}
	static eventEmitter() {
		return Emitter;
	}
}

export default Accordion;

// import Base from '../../basics/js/base';
// import { TweenLite } from 'gsap';

// // with inspiration from https://www.w3.org/TR/wai-aria-practices/examples/accordion/accordion.html

// /**
//  * @param {HTMLElement} el accordion container
//  * @param {Obj} options Object containing options for allowMultiple, allowToggle, duration
//  */

// class Accordion extends Base {
// 	get initialOptions() {
// 		return {
// 			allowMultiple: false,
// 			allowToggle: false,
// 			duration: 0.3
// 		}
// 	}
// 	constructor(el, options) {
// 		super(el, options);
// 		this.active = false;

// 		for (let key in this.el.dataset) {
// 			if( this.options.hasOwnProperty( key ) ) {
// 				this.options[key] = this.el.dataset[key] === "true" ? true : false;
// 			}
// 		}

// 		console.log('hi from Accordion', this.options);
// 		//const button = this.el.querySelector('.button');
// 		//button.addEventListener('click', this.handleInlineButtonClick.bind(this));

// 		//scope binding
// //		this.onToggle = this.onToggle.bind(this);
// 		this.triggers = this.find('*:not(.accordion) .js--accordion-trigger');
// 		console.log(this.triggers)

// 	}

// 	delegateEvents () {
// 		[].forEach.call(this.el.children, cn => { // allow nesting of accordions
// 			if(cn.classList.contains('accordion__title')) {
// 				this.on('click', cn.querySelector('.js--accordion-trigger'), this.onToggle.bind(this));

// 			}
// 		});

// 		this.on('keydown', this.el, this.onKeyDown.bind(this));
// 	}

// 	undelegateEvents () {
// 		[].forEach.call(this.el.children, cn => { // allow nesting
// 			if(cn.classList.contains('accordion__title')) {
// 				this.off('click', cn.querySelector('.js--accordion-trigger'), this.onToggle.bind(this));

// 			}
// 		});
// 		this.off('keydown', this.el, this.onKeyDown.bind(this));
// 	}

// 	onToggle(e) {
// 		e.preventDefault();
// 		e.stopPropagation();
// 		const target = e.currentTarget;
// 		//const content = target.parentElement.nextElementSibling;
// 		// Check if the current toggle is expanded.
// 		const isOpen = target.getAttribute('aria-expanded') == 'true';
// 		const active = this.find('[aria-expanded="true"]')[0];
// 		const content = this.find(`#${target.getAttribute('aria-controls')}`)[0];
// 		// without allowMultiple, close the open accordion
// 		if (!this.options.allowMultiple && active && active !== target) { // to close
// 			const tmpContent = this.find(`#${active.getAttribute('aria-controls')}`)[0];
// 			// animate content to closed
// 			TweenLite.to(tmpContent, this.options.duration, { height:0, onComplete: ()=>{
// 				// Set the expanded state on the triggering element
// 				active.setAttribute('aria-expanded', 'false');
// 				// Hide the accordion sections, using aria-controls to specify the desired section
// 				tmpContent.setAttribute('aria-hidden', 'true');
// 				// When toggling is not allowed, clean up disabled state
// 				if (!this.options.allowToggle) {
// 					active.removeAttribute('aria-disabled');
// 				}
// 			}})
// 		}
// 		if (!isOpen) { // to open state
// 			// Hide the accordion sections, using aria-controls to specify the desired section
// 			content.setAttribute('aria-hidden', 'false');
// 			content.style.height = "auto";
// 			TweenLite.to(content, this.options.duration, { opacity: 1});
// 			TweenLite.from(content, this.options.duration, { height:0, onComplete:()=>{
// 				// Set the expanded state on the triggering element
// 				target.setAttribute('aria-expanded', 'true');
// 				// set state class
// 				target.parentElement.classList.add('is-open');
// 				// If toggling is not allowed, set disabled state on trigger
// 				if (!this.options.allowToggle) {
// 				  target.setAttribute('aria-disabled', 'true');
// 				}
// 			}});
// 		} else if (this.options.allowToggle || this.options.allowMultiple && isOpen) { // to close
// 			TweenLite.to(content, this.options.duration, {height: 0, opacity: 0, onComplete:()=>{
// 				// Set the expanded state on the triggering element
// 				target.parentElement.classList.remove('is-open');
// 				target.setAttribute('aria-expanded', 'false');
// 				// Hide the accordion sections, using aria-controls to specify the desired section
// 				content.setAttribute('aria-hidden', 'true');
// 			}})
// 		}
// 	}

// 	onKeyDown (e) {
// 		console.log(e);
// 	}

// 	handleInlineButtonClick(e){
// 		e.stopPropagation();
// 	}

// }

// export default Accordion;
