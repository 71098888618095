import Emitter from './emitter';
import View from '../view';

class CommentForm extends View {
	constructor(el) {
		super(el);
		this.button = this.el.querySelector('.js--comment-form-button');
		this.tagsContainer = this.el.querySelector('.js--comment-form-tags');
		this.textArea = this.el.querySelector('textarea');
		this.tagButtons = this.el.querySelectorAll('.js--tag');
		this.toggleAllTagButton = this.el.querySelector('[data-all]');
		
				
		if (!this.el.classList.contains('is-active')) {
			document.querySelector('body').addEventListener('click', this.onBodyClick.bind(this) );
		}
		
		
		this.textArea.addEventListener('focus', this.onFocus.bind(this) );

		[...this.tagButtons].map((tagButton)=> {
			tagButton.addEventListener('click', this.onTagClick.bind(this) );
		});


		Emitter.on('textarea:resize', this.panelUpdate.bind(this) );
		
	}

	onFocus() {	
		this.tagsContainer.setAttribute('style','display: block;');
		if (this.button instanceof HTMLElement){
			this.button.setAttribute('style','display: inline-block;');
		}
		setTimeout(function(){
			this.el.classList.add('is-active');					
			this.panelUpdate();
		}.bind(this), 50);
	}

	onBodyClick(event) {
		
		if ( !this.el.classList.contains('is-active') ) return false;
		

		var foundEl = event.target.closest('.js--comment-form');

		if ( !foundEl ) {
			this.el.classList.remove('is-active');
			
			setTimeout(function() {
				this.tagsContainer.setAttribute('style','');
				if (this.button instanceof HTMLElement){
					this.button.setAttribute('style','');
				}
				this.panelUpdate();
			}.bind(this), 300);
		}
	}

	onTagClick(event){
		
		let tagButton = event.currentTarget;
		var isAllButton = tagButton.dataset.all ? true : false;

		if ( isAllButton ) {
			[...this.tagButtons].map((tagButton)=> {
				tagButton.classList.remove('is-active');
				
			});
		}
		else {
			this.toggleAllTagButton.classList.remove('is-active');			
		}
		tagButton.classList.toggle('is-active');		
	}

	panelUpdate(){
		Emitter.trigger('accordion:update');

		setTimeout(function() {
			Emitter.trigger('panel:update');
		}.bind(this), 500);
	}
}


export default CommentForm;
