import Emitter from './emitter';
import Utils from './utils';

class Overlay {


	constructor(settings) {
		let defaults = {
			isFixed: true,
			fixBody: true,
			isActive: false,
			isBlurred: false,
			whiteBg: false,
		};
		this.className = 'overlay overlay--modal';

		this.settings = Object.assign({}, settings, defaults);
		this.bodyRef = document.querySelector('body');
	}

	render() {
		let overlays = document.querySelectorAll('.overlay'),
			overlayCount = overlays.length;

		if (overlayCount > 0) {
			[...overlays].map((elem)=>{
				Utils.remove(elem);
			} );
		}


		this.el = document.createElement('div');
		this.el.className = this.className;
		this.bodyRef.appendChild(this.el);

		this.handleToggle();

		setTimeout(function(){
			this.el.classList.add('is-active');
		}.bind(this), 1);

		this.settings.isActive = true;

		this.el.classList.toggle('overlay--fixed', this.settings.isFixed);
		this.el.classList.toggle('overlay--white', this.settings.whiteBg);

		this.el.addEventListener('transitionend', this.onTransitionEnd.bind(this) );
		this.el.addEventListener('otransitionend', this.onTransitionEnd.bind(this) );
		this.el.addEventListener('webkitTransitionEnd', this.onTransitionEnd.bind(this) );
		this.el.addEventListener('click', this.onClick.bind(this) );

	}

	append(el) {
		this.el.appendChild(el);
	}

	hide() {
		if(!this.settings.isActive) { return; }

		this.el.classList.remove('is-active');

		this.handleToggle();

		this.settings.isActive = false;
	}

	handleToggle() {
		if (this.settings.fixBody) {
			if ( !this.settings.isActive ) {
				this.bodyRef.classList.add('is-fixed');
			}
			else {
				this.bodyRef.classList.remove('is-fixed');
			}
		}

		if (this.settings.isBlurred) {
			this.bodyRef.classList.toggle('is-blurred');
		}
	}

	onTransitionEnd(e) {
		if(!this.settings.isActive) {
			if ( this.el.parentElement != null ) Utils.remove(this.el);
		}
	}

	onClick(e) {
		if(e.target != this.el) { return; }
		Emitter.trigger('overlay:clicked', e);
		this.hide();
	}

	get eventEmitter() {
		return Emitter;
	}
}

export default Overlay;
