class AlphabeticNavigation {

	constructor(el) {
		this.el = el;
		this.buttons = this.el.querySelectorAll('.button');
		this.toggleAllButton = this.el.querySelector('[data-all]');
		this.notMultiple = this.el.dataset.multiple === 'false';

		[...this.buttons].map((button)=>{
			button.addEventListener('click', this.toggleFilterButton.bind(this));
		});
	}

	toggleFilterButton(event) {
		let button = event.currentTarget,
			isAllButton = button.dataset.all ? true : false;

		if ( isAllButton || this.notMultiple ) {
			[...this.buttons].map((button)=>{
				button.classList.remove('is-active');
			});
		}
		else {
			this.toggleAllButton.classList.remove('is-active');
		}
		button.classList.toggle('is-active');
	}
}

export default AlphabeticNavigation;
