import stickybits from "stickybits";
import focusvisible from "focus-visible";
import objectFitImages from "object-fit-images";
import "./polyfills";
import Accordion from "./components/accordion";
import AccordionController from "./components/accordion-controller";
import AlphabeticNavigation from "./components/alphabetic-navigation";
import AnimatedReveal from "./components/animated-reveal";
import Reveal from "./components/reveal";
import CommentForm from "./components/comment-form";
import CookieCompliance from "./components/cookie-compliance";
import CriterionList from "./components/criterion-list";
import EntriesNavigation from "./components/entries-navigation";
import FilterButtons from "./components/filter-buttons";
import Forms from "./components/forms";
import KeywordEdit from "./components/keyword-edit";
import KeywordToggle from "./components/keyword-toggle";
import ModalBox from "./components/modalbox";
import Notification from "./components/notification";
import PageHeaderSearch from "./components/page-header-search";
import PanelLayout from "./components/panel-layout";
import SearchLayer from "./components/search-layer";
import SearchFiltersOverlay from "./components/search-filters-overlay";
import Shortcuts from "./components/shortcuts";
import SiteFooter from "./components/site-footer";
import SiteHeader from "./components/site-header";
import Slider from "./components/slider";
import StatusControls from "./components/status-controls";
import SubscribePanel from "./components/subscribe-panel";
import ToggleButton from "./components/toggle-button";
import UpdateLog from "./components/update-log";
import Stickybits from "./components/stickybits";
import Emitter from "./components/emitter";
import Video from "./components/video";
import Tables from "./components/table";
import FacebookPageFeed from "./components/facebook-page-feed";
import { EVENTS } from "./constants";

class DesignsystemApp {
	constructor() {
		const isKanalen = document
			.querySelector("body")
			.classList.contains("theme-kanalen");
		if (isKanalen) {
			document.addEventListener("vue:started", () => {
				console.warn(
					"vue app ready and the designsystem app is aware."
				);
				// We are not sure if we should start the designsystem app from a b
				this.init();
			});
		} else {
			this.init();
		}
		document.addEventListener(EVENTS.DOCUMENT_UPDATE, () => {
			this.updateModules();
		});
	}
	init(e = "event") {
		// document.addEventListener('vue:started' this.initApp)

		let accordionControllers = document.querySelectorAll(
			".js--accordion-controller"
		);
		[...accordionControllers].map(elem => {
			let controller = new AccordionController(elem);
		});

		let accordions = document.querySelectorAll(".js--accordion");
		[...accordions].map(elem => {
			let noController = !elem.closest(".js--accordion-controller");
			if (noController) {
				let accordion = new Accordion(elem);
			}
		});

		let alphabeticNavigations = document.querySelectorAll(
			".js--alphabetic-navigation"
		);
		[...alphabeticNavigations].map(elem => {
			let alphabeticNavigation = new AlphabeticNavigation(elem);
		});

		let commentForms = document.querySelectorAll(".js--comment-form");
		[...commentForms].map(elem => {
			let commentForm = new CommentForm(elem);
		});

		let cookieCompliances = document.querySelectorAll(
			".js--cookie-compliance"
		);
		[...cookieCompliances].map(elem => {
			let cookieCompliance = new CookieCompliance(elem);
		});

		let criterionLists = document.querySelectorAll(".js--criterion-list");
		[...criterionLists].map(elem => {
			let criterionList = new CriterionList(elem);
		});

		let entriesNavigations = document.querySelectorAll(
			".js--entries-navigation"
		);
		[...entriesNavigations].map(elem => {
			let entriesNavigation = new EntriesNavigation(elem);
		});

		let filterButtonses = document.querySelectorAll(".js--filter-buttons");
		[...filterButtonses].map(elem => {
			let filterButtons = new FilterButtons(elem);
		});

		let formses = document.querySelectorAll(".form");
		[...formses].map(elem => {
			let forms = new Forms(elem);
		});

		let keywordEdits = document.querySelectorAll(".js--keyword-edit");
		[...keywordEdits].map(elem => {
			let keywordEdit = new KeywordEdit(elem);
		});

		let keywordToggles = document.querySelectorAll(".js--keyword-toggle");
		[...keywordToggles].map(elem => {
			let keywordToggle = new KeywordToggle(elem);
		});

		let modalTriggers = document.querySelectorAll(".js--open-modal");
		[...modalTriggers].map(trigger => {
			trigger.addEventListener("click", function() {
				let template = this.getAttribute("data-template")
						? document.querySelector(
								"#" + this.getAttribute("data-template")
						  )
						: null,
					content = template
						? template.innerHTML
						: document.createElement("<p>TEMPLATE MISSING</p>"),
					fixBody = this.getAttribute("data-fixbody")
						? this.getAttribute("data-fixbody") == "true"
						: false,
					cssClasses = template.getAttribute("data-classes")
						? template.getAttribute("data-classes")
						: "";

				cssClasses += this.getAttribute("data-classes")
					? " " + this.getAttribute("data-classes")
					: "";

				let instantiationFail = false;

				if (typeof template == "undefined") {
					instantiationFail = true;
				}
				if (typeof content == "undefined") {
					instantiationFail = true;
				}
				if (typeof fixBody == "undefined") {
					instantiationFail = true;
				}
				if (typeof cssClasses == "undefined") {
					instantiationFail = true;
				}
				if (instantiationFail) return false;

				let modal = new ModalBox({
					content: content,
					cssClasses: cssClasses,
					fixBody: fixBody
				});
				modal;
			});
		});

		let notifications = document.querySelectorAll(".js--notification");
		[...notifications].map(elem => {
			let notification = new Notification(elem);
		});

		let pageHeaderSearches = document.querySelectorAll(
			".js--page-header-search"
		);
		[...pageHeaderSearches].map(elem => {
			let pageHeaderSearch = new PageHeaderSearch(elem);
		});

		let panelLayouts = document.querySelectorAll(".js--panel-layout");
		[...panelLayouts].map(elem => {
			let panelLayout = new PanelLayout(elem);
		});

		let reveals = document.querySelectorAll(".js--reveal");
		[...reveals].map(elem => {
			let reveal = new Reveal(elem);
		});

		let searchFiltersOverlays = document.querySelectorAll(
			".js--search-filters-overlay"
		);
		[...searchFiltersOverlays].map(elem => {
			let searchFiltersOverlay = new SearchFiltersOverlay(elem);
		});

		let searchLayers = document.querySelectorAll(".js--search-layer");
		[...searchLayers].map(elem => {
			let searchLayer = new SearchLayer(elem);
		});

		let shortcutses = document.querySelectorAll(".js--shortcuts");
		[...shortcutses].map(elem => {
			let shortcuts = new Shortcuts(elem);
		});

		let siteFooters = document.querySelectorAll(".js--site-footer");
		[...siteFooters].map(elem => {
			let siteFooter = new SiteFooter(elem);
		});

		let siteHeaders = document.querySelectorAll(".js--site-header");
		[...siteHeaders].map(elem => {
			let siteHeader = new SiteHeader(elem);
		});

		let sliders = document.querySelectorAll(".js--slider");
		[...sliders].map(elem => {
			let slider = new Slider(elem);
		});

		let statusControlses = document.querySelectorAll(
			".js--status-controls"
		);
		[...statusControlses].map(elem => {
			let statusControls = new StatusControls(elem);
		});

		let subscribePanels = document.querySelectorAll(".js--subscribe-panel");
		[...subscribePanels].map(elem => {
			let subscribePanel = new SubscribePanel(elem);
		});

		let toggleButtons = document.querySelectorAll(".js--toggle-button");
		[...toggleButtons].map(elem => {
			let toggleButton = new ToggleButton(elem);
		});

		let updateLogs = document.querySelectorAll(".js--update-log");
		[...updateLogs].map(elem => {
			let updateLog = new UpdateLog(elem);
		});

		//Update the masonry layout
		let masonryUpdates = document.querySelectorAll(".js--masonry-update");
		[...masonryUpdates].map(elem => {
			elem.addEventListener("click", () =>
				Emitter.trigger("panel:update")
			);
		});

		let videos = document.querySelectorAll(".js--video");
		[...videos].map(elem => {
			let video = new Video(elem);
		});

		const fbFeed = document.querySelector(".facebook-wrapper");
		if (fbFeed) new FacebookPageFeed(fbFeed);

		const stickybits = new Stickybits();
	}
	// for modules that's ready to be re initiated
	updateModules() {
		const reveals = document.querySelectorAll(".js--reveal");
		[...reveals].forEach(elem => {
			const reveal = new Reveal(elem);
		});
		const formses = document.querySelectorAll(".form");
		[...formses].map(elem => {
			let forms = new Forms(elem);
		});

		let accordionControllers = document.querySelectorAll(
			".js--accordion-controller"
		);
		[...accordionControllers].map(elem => {
			let controller = new AccordionController(elem);
		});

		let accordions = document.querySelectorAll(".js--accordion");
		[...accordions].map(elem => {
			let noController = !elem.closest(".js--accordion-controller");
			if (noController) {
				let accordion = new Accordion(elem);
			}
		});
	}

	static factory() {
		return new PrototypeApp();
	}
}

const App = new DesignsystemApp();

export {
	App,
	AccordionController,
	Accordion,
	AlphabeticNavigation,
	AnimatedReveal,
	Reveal,
	CommentForm,
	CookieCompliance,
	CriterionList,
	EntriesNavigation,
	FilterButtons,
	Forms,
	KeywordEdit,
	KeywordToggle,
	ModalBox,
	Notification,
	PageHeaderSearch,
	PanelLayout,
	SearchLayer,
	SearchFiltersOverlay,
	Shortcuts,
	SiteFooter,
	SiteHeader,
	Slider,
	StatusControls,
	SubscribePanel,
	ToggleButton,
	UpdateLog,
	Stickybits,
	FacebookPageFeed
};
