class TableScroll {
	constructor(el) {
		this.el = el;
    this.table = el.querySelector('table');
    this.wrapper = el.querySelector('.table-wrapper');
    this.scrollButton = el.querySelector('.box__table-scroll');

    if (this.wrapper && this.scrollButton) this.scrollButton.addEventListener('click', this.handleScroll.bind(this));
    window.addEventListener('resize', this.handleResize.bind(this));
    this.handleResize();
  }

  handleResize() {
    if (this.table.offsetWidth > this.el.offsetWidth) {
      this.el.classList.add('box--fade');
    } else {
      this.el.classList.remove('box--fade');
    }
  }

  handleScroll() {
    this.wrapper.scrollLeft += 50;
  }

}

export default TableScroll;


let tables = document.querySelectorAll('.box.box--table');
[...tables].map((elem)=> {
  let table = new TableScroll(elem);
});