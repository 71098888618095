class Utils {

	/**
	 * getHeight - for elements with display:none
	 **/
	getHeight(el) {
	    var el_style      = window.getComputedStyle(el),
	        el_display    = el_style.display,
	        el_position   = el_style.position,
	        el_visibility = el_style.visibility,
	        el_max_height = el_style.maxHeight.replace('px', '').replace('%', ''),

	        wanted_height = 0;


	    // if its not hidden we just return normal height
	    if(el_display !== 'none' && el_max_height !== '0') {
	        return el.offsetHeight;
	    }

	    // the element is hidden so:
	    // making the el block so we can meassure its height but still be hidden
	    el.style.position   = 'absolute';
	    el.style.visibility = 'hidden';
	    el.style.display    = 'block';

	    wanted_height     = el.offsetHeight;

	    // reverting to the original values
	    el.style.display    = el_display;
	    el.style.position   = el_position;
	    el.style.visibility = el_visibility;

	    return wanted_height;
	}

	offset(elem) {
		var docElem, win,
			box = { top: 0, left: 0 },
			doc = elem && elem.ownerDocument;

		docElem = doc.documentElement;

		if ( typeof elem.getBoundingClientRect !== typeof undefined ) {
			box = elem.getBoundingClientRect();
		}
		win = this.getWindow( doc );
		return {
			top: box.top + win.pageYOffset - docElem.clientTop,
			left: box.left + win.pageXOffset - docElem.clientLeft
		};
	}

	isWindow(obj) {
		return obj != null && obj === obj.window;
	}

	getWindow(elem) {
		return this.isWindow( elem ) ? elem : elem.nodeType === 9 && elem.defaultView;
	}

	remove(elem) {
		elem.parentNode.removeChild(elem);
	}

	elProxy(element) {
		return element instanceof HTMLElement ? element : (element.el || document.createElement('span'));
	}

	uniqueId(length=8) {
		let str = '';
		for (var i = 1; i < length + 1; i = i + 8) {
			str += Math.random().toString(36).substr(2, 10)
		}
		return ('_' + str).substr(0, length);
	}

}

export default new Utils();
