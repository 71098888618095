import Emitter from './emitter';

class SiteHeader {

	constructor(el) {
		this.el = el;
		this.bodyRef = document.querySelector('body');
		this.htmlRef = document.querySelector('html');

		this.mainNavigationRef = this.el.querySelector('.main-navigation');
		this.mainNavigationToggle = this.mainNavigationRef.querySelector('.js--main-navigation-toggle');
		this.mainNavigationToggle.addEventListener('click', this.navigationToggleHandler.bind(this));

		Emitter.on('search:open', this.forceClose.bind(this) );
	}

	navigationToggleHandler(event) {
		this.mainNavigationRef.classList.toggle('is-active');
		this.bodyRef.classList.toggle('is-fixed');
		this.htmlRef.classList.toggle('is-burger-mode');
		this.mainNavigationToggle.setAttribute('aria-expanded', (this.mainNavigationToggle.getAttribute('aria-expanded') === 'false' ? 'true': 'false'))
	}

	forceClose(event) {
		this.mainNavigationRef.classList.remove('is-active');
		this.bodyRef.classList.remove('is-fixed');
		this.htmlRef.classList.remove('is-burger-mode');
	}
}

export default SiteHeader;
