import Emitter from './emitter';

import Masonry from 'masonry-layout';

class PanelLayout {

	constructor(el) {
		this.el = el;
		var settings = {
			itemSelector: ".panel",
			gutter: 30,
			columnWidth: ".grid-sizer",
			percentPosition: true
		};

		this.msnry = new Masonry( this.el, settings);
		this.msnry.layout();

		Emitter.on('panel:update', this.panelUpdate.bind(this) );
	}

	panelUpdate() {
		this.msnry.layout();
		// this.msnry.reloadItems()
	}
}

export default PanelLayout;

