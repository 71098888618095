import stickybits from 'stickybits';

class Shortcuts {

	constructor (el) {
		this.el = el;

		this.active = false;
		this.trigger = this.el.querySelector('.js--shortcuts-button');

		this.trigger.addEventListener('click', this.onToggleButton.bind(this));

		stickybits('.js--shortcuts', {useStickyClasses: true});

		this.dropdown = this.el.querySelector('.js--shortcuts-dropdown');
	}

	onToggleButton () {
		this.active = !this.active;
		if ( this.active ) { this.show(); }
		else { this.hide(); }
	}

	show () {
		this.dropdown.setAttribute('style','display: block;');
		setTimeout(function(){
			this.el.classList.add('is-active');
		}.bind(this), 50);
	}

	hide() {
		this.el.classList.remove('is-active');
		setTimeout(function(){
			this.dropdown.setAttribute('style','');
		}.bind(this), 200);
	}
}

export default Shortcuts;
