import flatpickr from "flatpickr/dist/flatpickr";
import { Danish } from "../vendor/flatpickr/da";
import Emitter from "./emitter";
import View from "../view";
import cuid from "cuid";
import { html, render } from "es5-lit-html";

class Forms extends View {
	constructor(el) {
		super(el);

		let formFields = this.el.querySelectorAll(".form__field");

		[...formFields].map(elem => {
			if (!elem.classList.contains("is-bound")) {
				if (elem.classList.contains("form__field--select")) {
					if (!elem.classList.contains("js--select-multiple")) {
						new Select(elem);
					}
				}
				if (elem.classList.contains("form__field--radio")) {
					new Radio(elem);
				}
				if (elem.classList.contains("form__field--textarea")) {
					new Textarea(elem);
				}
				if (elem.classList.contains("form__field--checkbox")) {
					new CheckBox(elem);
				}
				if (elem.classList.contains("form__field--text")) {
					new Text(elem);
				}
				if (elem.classList.contains("form__field--file")) {
					new FileUpload(elem);
				}
				if (elem.classList.contains("form__field--date")) {
					new DatePicker(elem);
				}
				if (elem.classList.contains("js--select-multiple")) {
					new MultipleSelect(elem);
				}
			}
		});
	}
}

//Select
class Select {
	constructor(el) {
		this.el = el;

		this.el.classList.add("is-bound");
		this.formItem = this.el.querySelector(".form__item");

		this.formItem.addEventListener("focus", this.onFocus.bind(this));
		this.formItem.addEventListener("blur", this.onBlur.bind(this));
		this.formItem.addEventListener("change", this.onChange.bind(this));
		this.formItem.addEventListener("select", this.onSelect.bind(this));
	}

	onFocus() {
		if (this.el.classList.contains("is-disabled")) return;
		this.el.classList.add("has-focus");
		this.el.classList.add("is-filled");
	}

	onChange() {
		this.formItem.blur();
		this.onBlur();
	}

	onSelect() {}

	onBlur() {
		this.el.classList.remove("has-focus");
		if (
			this.formItem.options[this.formItem.selectedIndex].text == "default"
		) {
			this.el.classList.remove("is-filled");
		}
	}
}

//Radio
class Radio {
	constructor(el) {
		this.el = el;

		this.el.classList.add("is-bound");
		this.formItem = this.el.querySelector(".form__item");
		Emitter.on("radio:change", this.onChange.bind(this));

		this.formItem.addEventListener("click", this.onClick.bind(this));
	}

	onClick(e) {
		Emitter.trigger("radio:change", e, this.formItem.name);
	}

	onChange(e, name) {
		if (this.formItem.name === name) {
			this.el.classList.toggle("is--checked", this.formItem.checked);
		}
	}
}

//CheckBox
class CheckBox {
	constructor(el) {
		this.el = el;
		this.formItem = this.el.querySelector(".form__item");

		this.el.classList.add("is-bound");
		this.formItem.addEventListener("click", this.onClick.bind(this));
	}

	onClick(e) {}
}

//Textarea
class Textarea {
	constructor(el) {
		this.el = el;

		this.el.classList.add("is-bound");
		this.formItem = this.el.querySelector(".form__item");
		this.minHeight = this.formItem.scrollHeight;
		this.maxHeight = 150;

		this.formItem.addEventListener("focus", this.onFocus.bind(this));
		this.formItem.addEventListener("blur", this.onBlur.bind(this));
		this.formItem.addEventListener("input", this.adjustHeight.bind(this));

		window.addEventListener("resize", this.adjustHeight.bind(this));

		// we adjust height to the initial content
		this.adjustHeight();
	}

	onFocus() {
		if (this.el.classList.contains("is-disabled")) return;
		this.el.classList.add("has-focus");
		this.el.classList.add("is-filled");
	}

	onBlur() {
		this.el.classList.remove("has-focus");
		if (this.formItem.value.length == 0)
			this.el.classList.remove("is-filled");
	}

	adjustHeight() {
		// compute the height difference which is caused by border and outline
		var outerHeight = parseInt(
			window.getComputedStyle(this.formItem).height,
			10
		);
		var diff = outerHeight - this.formItem.clientHeight;

		// set the height to 0 in case of it has to be shrinked
		this.formItem.style.height = 0;

		// set the correct height
		// el.scrollHeight is the full height of the content, not just the visible part
		this.formItem.style.height =
			Math.min(
				this.maxHeight,
				Math.max(this.minHeight, this.formItem.scrollHeight + diff)
			) + "px";

		Emitter.trigger("textarea:resize");
	}
}

//Text
class Text {
	constructor(el) {
		this.el = el;

		this.el.classList.add("is-bound");
		this.formItem = this.el.querySelector(".form__item");

		this.formItem.addEventListener("focus", this.onFocus.bind(this));
		this.formItem.addEventListener("blur", this.onBlur.bind(this));
	}

	onFocus() {
		if (this.el.classList.contains("is-disabled")) return;
		this.el.classList.add("has-focus");
		this.el.classList.add("is-filled");
	}

	onBlur() {
		this.el.classList.remove("has-focus");
		if (this.formItem.value.length == 0)
			this.el.classList.remove("is-filled");
	}
}

//FileUpload
class FileUpload {
	constructor(el) {
		this.el = el;

		this.el.classList.add("is-bound");
		this.formItem = this.el.querySelector(".form__item");
		this.multipleCaption = this.formItem.getAttribute(
			"data-multiplecaption"
		);
		this.fileNames = this.find(".file-names");

		this.formItem.addEventListener("change", this.changeHandle.bind(this));
	}

	changeHandler(event) {
		var fileName = "";
		var fileList = this.formItem.files;
		if (fileList && fileList.length > 1) {
			fileName = (this.multipleCaption || "").replace(
				"{count}",
				fileList.length
			);
		} else {
			fileName = event.target.value.split("\\").pop();
		}

		if (fileName) this.fileNames.text = fileName;
	}
}

class DatePicker {
	constructor(el) {
		this.el = el;
		this.options = Object.assign({ locale: Danish }, this.el.dataset);
		this.calendar = flatpickr(
			this.el.querySelector(".form__item"),
			this.options
		);
	}
}

class MultipleSelect {
	constructor(el) {
		this.el = el;

		this.el.classList.add("is-bound");
		this.container = this.el.querySelector(".select-multiple-container");
		this.hiddenSelect = this.el.querySelector(".hidden-select");

		this.controllerId = cuid();

		this.selectedItems = [];

		render(this.render(), this.container);

		this.list = this.el.querySelector(".dropdown__list");
		this.triggerBtn = this.el.querySelector(".form__item");
		this.listContainer = this.el.querySelector(".dropdown__list-container");
		this.dropdownArrow = this.el.querySelector(".dropdown__arrow");
		this.listItems = this.el.querySelectorAll(".dropdown__list-item");
		this.selectAllBtn = this.el.querySelector(".select-all");
		this.clearAllBtn = this.el.querySelector(".clear-all");
		this.initialLabel = this.el.querySelector(
			".form__label__text"
		).innerText;
		this.isOpen = false;

		[...this.hiddenSelect.options].forEach(option => {
			if (option.selected === true) {
				this.selectedItems.push(option.dataset.id);
			}
		});
		this.triggerBtn.id = this.controllerId;

		this.selectText = this.el.querySelector(".select-text");

		this.listItemIds = [];

		// Add each list item's id to the listItems array
		this.listItems.forEach(item => this.listItemIds.push(item.dataset.id));

		this.addEvents();
	}

	addEvents() {
		this.setSelectedListItem();
		// Init dropdown
		this.triggerBtn.addEventListener("click", e =>
			this.toggleListVisibility(e)
		);

		this.selectAllBtn.addEventListener("click", e => {
			this.selectAllOptions(e);
		});
		this.clearAllBtn.addEventListener("click", e => {
			this.clearAllOptions(e);
		});
		this.listContainer.addEventListener("transitionend", () => {
			this.listContainer.classList.toggle("is-hidden", !this.isOpen);
		});
		// key events
		window.addEventListener("keydown", this.handleKeys.bind(this), false);
		document.addEventListener(
			"click",
			e => {
				if (
					this.isOpen &&
					!e.target.closest(".form__field--select-multiple")
				) {
					this.closeList();
				}
			},
			false
		);
		// checkboxes
		this.el.addEventListener("click", e => {
			// checkbox click
			if (
				e.target.type === "checkbox" &&
				e.target.classList.contains("form__item")
			) {
				const item = e.target;
				if (!this.selectedItems.includes(item.id)) {
					this.selectedItems.push(item.id);
					[...this.hiddenSelect.options].forEach(option => {
						if (option.dataset.id === item.id) {
							option.setAttribute("selected", true);
						}
					});
				} else {
					this.selectedItems.splice(
						this.selectedItems.indexOf(item.id),
						1
					);
					[...this.hiddenSelect.options].forEach(option => {
						if (option.dataset.id === item.id) {
							option.removeAttribute("selected", true);
						}
					});
				}
				this.setSelectedListItem(e);
				this.bundleBtnColorToggle(e);
			}
		});
	}

	handleKeys(e) {
		if (!this.isOpen) return;
		// e.preventDefault();
		switch (e.keyCode) {
			case ENTER_KEY_CODE:
				if (e.target.id === this.controllerId) {
					window.requestAnimationFrame(() => {
						this.setSelectedListItem(e);
						this.bundleBtnColorToggle(e);
						this.closeList();
					});
				} else {
					this.selectListItem(e);
				}
				return;
			case SPACEBAR_KEY_CODE[1]:
				if (e.target.classList.contains("dropdown__list-item")) {
					this.selectListItem(e);
				}

			case DOWN_ARROW_KEY_CODE:
				this.focusNextListItem(DOWN_ARROW_KEY_CODE);
				return;

			case UP_ARROW_KEY_CODE:
				this.focusNextListItem(UP_ARROW_KEY_CODE);
				return;

			case ESCAPE_KEY_CODE:
				this.closeList();
				return;

			default:
				return;
		}
	}
	selectListItem(e) {
		e.preventDefault();
		const item = e.target;
		const checkbox = item.querySelector("input");
		if (!this.selectedItems.includes(item.dataset.id)) {
			this.selectedItems.push(item.dataset.id);
			[...this.hiddenSelect.options].forEach(option => {
				if (option.dataset.id === item.dataset.id) {
					option.setAttribute("selected", true);
				}
			});

			if (checkbox) {
				checkbox.checked = true;
			}
		} else {
			this.selectedItems.splice(
				this.selectedItems.indexOf(item.dataset.id),
				1
			);
			[...this.hiddenSelect.options].forEach(option => {
				if (option.dataset.id === item.dataset.id) {
					option.removeAttribute("selected", true);
				}
			});
			if (checkbox) {
				checkbox.checked = false;
			}
		}
		this.setSelectedListItem(e);
		this.bundleBtnColorToggle(e);
	}
	bundleBtnColorToggle(e) {
		this.selectAllBtn.disabled =
			this.selectedItems.length === this.listItems.length;
		this.clearAllBtn.disabled = this.selectedItems.length === 0;
	}

	// takes an event and updates the currently selected item in the "select" box.
	setSelectedListItem(e) {
		let ifOneChosenText;
		this.listItems.forEach(item => {
			if (this.selectedItems[0] === item.dataset.id) {
				ifOneChosenText = item.innerText;
			}
		});

		let text = "";
		if (this.selectedItems.length > 1) {
			text = `${this.selectedItems.length} valgt`;
		} else if (this.selectedItems.length == 1) {
			text = ifOneChosenText;
		} else {
			text = this.initialLabel;
		}

		this.selectText.innerText = text.trim();
	}

	// closes the list and updates the aria-expanded value.
	closeList() {
		this.isOpen = false;
		this.list.classList.remove("open");
		// this.dropdownArrow.classList.remove("expanded");
		this.listContainer.setAttribute("aria-expanded", false);
		this.triggerBtn.focus();
	}

	// takes an event. If the Escape key was pressed, close the list. Otherwise, if the user has clicked or pressed the Spacebar or Enter key, toggle the expanded state and update the aria-expanded value accordingly. Finally, if the down or up arrow keys were pressed, focus the next list item.
	toggleListVisibility(e) {
		this.isOpen = !this.isOpen;
		if (this.isOpen) {
			this.listContainer.classList.remove("is-hidden");
		}
		window.requestAnimationFrame(() => {
			this.listContainer.setAttribute("aria-expanded", this.isOpen);
		});
	}

	selectAllOptions(e) {
		this.listItems.forEach(item => {
			const checkbox = item.querySelector("input");
			if (!this.selectedItems.includes(item.dataset.id)) {
				this.selectedItems.push(item.dataset.id);
				checkbox.checked = true;
			}
		});
		[...this.hiddenSelect.options].forEach(option => {
			option.setAttribute("selected", true);
		});
		this.bundleBtnColorToggle(e);
		this.setSelectedListItem();
	}
	clearAllOptions(e) {
		this.listItems.forEach(item => {
			const checkbox = item.querySelector("input");
			if (this.selectedItems.includes(item.dataset.id)) {
				this.selectedItems.splice(
					this.selectedItems.indexOf(item.dataset.id),
					1
				);
				checkbox.checked = false;
			}
			[...this.hiddenSelect.options].forEach(option => {
				option.removeAttribute("selected", true);
			});
		});
		this.bundleBtnColorToggle();
		this.setSelectedListItem();
	}

	/* takes a direction which is either the const DOWN_ARROW_KEY_PRESSED or UP_ARROW_KEY_PRESSED. If the user is currently focused on the "select", focus on the first list item. Otherwise we need to find the index of the currently focused list item. This is where the listItemsId array comes in handy. Now that we know where in the list the currently focused item is, we can decide what to do. If the user pressed the down arrow key, and they're not at the last list item, focus on the next list item. If the user pressed the up arrow key, and they're not at the first list item, focus on the previous list item. */
	focusNextListItem(direction) {
		if (
			document.activeElement.id === this.controllerId &&
			direction !== UP_ARROW_KEY_CODE
		) {
			this.list.children[0].focus();
		} else {
			const currentActiveElIndex = this.listItemIds.indexOf(
				document.activeElement.dataset.id
			);

			if (direction === DOWN_ARROW_KEY_CODE) {
				if (currentActiveElIndex < this.listItemIds.length - 1) {
					this.list.children[currentActiveElIndex + 1].focus();
				}
			} else if (direction === UP_ARROW_KEY_CODE) {
				if (currentActiveElIndex > 0) {
					this.list.children[currentActiveElIndex - 1].focus();
				}
				if (currentActiveElIndex === 0) {
					this.triggerBtn.focus();
				}
			}
		}
	}
	render() {
		return html`
			<div class="dropdown">
				<div
					aria-expanded="false"
					role="list"
					class="dropdown__list-container is-hidden"
				>
					<div class="dropdown__bundle-select-btns">
						<button
							class="dropdown__bundle-btn select-all"
							type="button"
						>
							${this.el.dataset.chooseAllLabel}
						</button>
						<span>|</span>
						<button
							class="dropdown__bundle-btn clear-all"
							type="button"
						>
							${this.el.dataset.uncheckAllLabel}
						</button>
					</div>
					<ul class="dropdown__list">
						${[...this.hiddenSelect.options].map(
							(item, i) =>
								html`
									<li
										class="dropdown__list-item dropdown__list-item--checkbox"
										data-id=${item.dataset.id}
										tabindex="0"
									>
										<label for="${item.dataset.id}">
											<input
												class="checkbox form__item"
												id="${item.dataset.id}"
												value="${item.value}"
												name="check-name"
												type="checkbox"
												?checked=${item.selected}
												tabindex="-1"
											/>

											<span class="form__label__text">
												<span>${item.innerText} </span>
											</span>
										</label>
									</li>
								`
						)}
					</ul>
				</div>
			</div>
		`;
	}
}

export default Forms;
