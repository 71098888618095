class FilterButtons {

	constructor(el) {
		this.el = el;

		this.labelRefId = this.el.dataset.labelid;

		if ( this.labelRefId.length > 0 ) {
			this.labelRef = document.querySelector('#'+this.labelRefId);
		}

		this.buttons = this.el.querySelectorAll('.button');
		this.toggleAllButton = this.el.querySelector('[data-all]');

		[...this.buttons].map((button)=>{
			button.addEventListener('click',this.toggleFilterButton.bind(this) );
		});
	}


	toggleFilterButton(event) {
		var selectedCount = 0;

		var isAllButton = event.currentTarget.dataset.all ? true : false;
		if ( isAllButton ) {
			[...this.buttons].map((button)=>{
				button.classList.remove('is-active');
			});
		}
		else {
			this.toggleAllButton.classList.remove('is-active');
		}
		event.currentTarget.classList.toggle('is-active');

		setTimeout(function() {
			selectedCount = this.el.querySelectorAll('.button.is-active').length;
			if ( isAllButton ) selectedCount = 0;

			if ( this.labelRef instanceof HTMLElement ) {
				this.labelRef.textContent = selectedCount + " valgt";
			}
			if ( selectedCount == 0 ) {
				this.labelRef.textContent = "";
			}
		}.bind(this),50);
	}
}

export default FilterButtons;
