import Utils from './utils';

class Notification {
	constructor(el) {
		this.el = el;
		this.el.querySelector('.js--close-button').addEventListener('click',this.close.bind(this));
	}
	close () {
		Utils.remove(this.el);
	}
}

export default Notification;
