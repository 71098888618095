import View from '../view';
class StatusControls extends View {

	constructor(el) {
		super(el);
		this.content = this.el.querySelector('.js--status-controls-content');
		this.statusControlsToggle = this.el.querySelector('.js--status-controls-toggle');
		this.statusControlsToggle.addEventListener('click', this.toggleStatusControls.bind(this));

		this.bodyRef = document.querySelector('body');
		this.bodyRef.addEventListener('click', this.onBodyClick.bind(this));
	}

	toggleStatusControls(event) {
		event.stopPropagation();
		this.active = !this.active;
		if ( this.active ) { this.show(); }
		else { this.hide(); }
	}

	show() {
		this.content.setAttribute('style','display: block;');
		setTimeout(function(){
			this.el.classList.add('is-active');
		}.bind(this), 50);
	}

	hide() {
		this.el.classList.remove('is-active');
		setTimeout(function(){
			this.content.setAttribute('style','');
		}.bind(this), 200);
	}

	onBodyClick(event) {
		if ( !this.el.classList.contains('is-active') ) return false;
		var foundEl = event.target.closest('.js--status-controls');
		if ( !foundEl ) {
			this.el.classList.remove('is-active');
			this.content.setAttribute('style','');
			this.active = false;
		}
	}
}

export default StatusControls;
