class UpdateLog {

	constructor(el) {
		this.el = el;

		this.active = false;

		this.trigger = document.querySelector('.js--update-log-toggle');
		this.trigger.addEventListener('click', this.toggleUpdateLog.bind(this));

		this.bodyRef = document.querySelector('body');
		this.bodyRef.addEventListener('click', this.onBodyClick.bind(this));
	}

	toggleUpdateLog(event) {
		event.stopPropagation();

		this.active = !this.active;
		if ( this.active ) { this.show(); }
		else { this.hide(); }
	}

	onBodyClick(event) {
		if ( !this.el.classList.contains('is-active') ) return false;
		var foundEl = event.target.closest('.js--update-log');
		if ( !foundEl ) {
			this.el.classList.remove('is-active');
		}
	}

	show() {
		this.el.setAttribute('style','display: block;');
		setTimeout(function(){
			this.el.classList.add('is-active');
		}.bind(this), 50);
	}

	hide() {
		this.el.classList.remove('is-active');
		setTimeout(function(){
			this.el.setAttribute('style','');
		}.bind(this), 200);
	}

}

export default UpdateLog;
