import Utils from './utils';

class KeywordEdit {
	constructor(el) {
		this.el = el;

		this.keywordRemove = this.el.querySelector('.js--keyword-remove');
		this.keywordInput = this.el.querySelector('.js--keyword-input');

		this.keywordRemove.addEventListener('click', this.removeHandler.bind(this));
		this.keywordInput.addEventListener('input', this.inputHandler.bind(this));
	}

	removeHandler(event) {
		Utils.remove(this.el);
	}

	inputHandler(event) {

	}
}

export default KeywordEdit;
