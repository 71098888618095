import { isCookieEnabled, getCookie, setCookie, removeCookie } from 'tiny-cookie';
import Utils from './utils';
import View from '../view';

class CookieCompliance extends View {

	constructor(el) {
		super(el);

		let cookie = getCookie(window.cookieProps.name);

		let acceptButton = this.el.querySelector('.js--accept');
		let rejectButton = this.el.querySelector('.js--reject');

		acceptButton.addEventListener('click', this.acceptHandler.bind(this) );
		rejectButton.addEventListener('click', this.rejectHandler.bind(this) );


		if ( cookie == 'true' ){
			Utils.remove(this.el);
		}
		else {
			setTimeout(function(){
				this.el.classList.add('is-active');
			}.bind(this),3000);
		}
	}

	acceptHandler() {
		setCookie(window.cookieProps.name, "true", { expires:this.expirationDate(), domain:window.cookieProps.domain } );

		Utils.remove(this.el);
	}

	rejectHandler() {
		setCookie(window.cookieProps.name, "false", { expires:this.expirationDate(), domain:window.cookieProps.domain } );

		Utils.remove(this.el);
	}

	expirationDate() {
		let date = new Date();
		date.setDate(date.getDate() + window.cookieProps.days);
		let dateStr = date.toGMTString();

		return dateStr;
	}
}

export default CookieCompliance;
