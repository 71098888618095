import Utils from './utils';

class EntriesNavigation {

	constructor(el) {
		this.el = el;

		this.entryButtons = null;
		this.animationDuration = 150;
		this.secondaries = null;
		this.secondariesContent = null;

		this.oldEntryId = -1;
		this.currentEntryId = -1;
		this.oldSecondaryId = -1;
		this.currentSecondaryId = -1;
		this.currentSecondaryContentId = -1;

		this.currentEntryButton = null;
		this.currentSecondary = null;
		this.secondaryOpen = false;

		this.savedWindowWidth = 0;

		this.secondaryCloseButtons = this.el.querySelectorAll('.js--secondary-close');
		[...this.secondaryCloseButtons].map((secondaryCloseButton)=>{
			secondaryCloseButton.addEventListener('click',this.secondaryCloseHandler.bind(this));
		});

		this.secondaries = this.el.querySelectorAll('.js--entries-navigation-secondary');
		this.secondariesContent = this.el.querySelectorAll('.js--secondary-content');
		this.entryButtons = this.el.querySelectorAll('.js--primary-entry');


		this.bodyRef = document.querySelector('body');
		this.htmlRef = document.querySelector('html');


		this.savedWindowWidth = window.innerWidth;

		window.addEventListener('resize', this.resizeHandler.bind(this) );

		[...this.entryButtons].map((entryButton)=>{
			entryButton.addEventListener('click', this.clickHandler.bind(this) );
		});

	}

	clickHandler(event) {
		this.primaryEntryHandler(event);
	}

	primaryEntryHandler(event) {

		if ( !event.currentTarget ) return false;


		this.oldEntryId = this.currentEntryId;
		if ( this.currentEntryButton instanceof HTMLElement ) {
			this.currentEntryButton.setAttribute('aria-expanded', 'false');
		}
		this.currentEntryButton = event.currentTarget;

		this.currentEntryId = parseInt(this.currentEntryButton.getAttribute('data-entryid'));
		this.oldSecondaryId = this.currentSecondaryId;

		this.currentSecondaryId = parseInt(this.currentEntryButton.getAttribute('data-secondaryid'));
		this.currentSecondaryContentId = parseInt(this.currentEntryButton.getAttribute('data-contentid'));

		this.currentSecondary = this.secondaries[this.currentSecondaryId];
		this.currentSecondaryContent = this.currentSecondary.querySelector("[data-contentid='"+this.currentSecondaryContentId+"']");

		// Log.db("cEI="+this.currentEntryId+", cSI="+this.currentSecondaryId+", cSCI="+this.currentSecondaryContentId);
		// Log.db("this.secondaries",this.secondaries);
		// Log.db("this.currentSecondary",this.currentSecondary);
		// Log.db("this.currentSecondaryContent",this.currentSecondaryContent);

		if ( this.oldEntryId == this.currentEntryId ) {
			//clicked same button again
			this.controlSecondaryNavigation(false);
		}
		else if ( this.oldSecondaryId == this.currentSecondaryId ) {
			//clicked something new - same secondary
			this.controlSecondaryNavigation(true);
		}
		else {
			//clicked something new - secondary open
			this.controlSecondaryNavigation(true, true);
		}
	}

	resetSecondaryContent(animate) {
		[...this.secondariesContent].map((elem)=>{
			elem.classList.remove('is-animating');
			if ( animate ) setTimeout(function() {elem.classList.remove('is-active');}.bind(this),this.animationDuration);
			else elem.classList.remove('is-active');
		});
	}

	resetSecondary(animate, force) {
		[...this.secondaries].map((elem)=>{
			if ( elem != this.currentSecondary || force ) {
				elem.classList.remove('is-animating');
				if ( animate ) setTimeout(function() {elem.classList.remove('is-active');}.bind(this),this.animationDuration);
				else elem.classList.remove('is-active');
				elem.setAttribute("style","");
			}
		});
	}

	controlSecondaryNavigation(showingNewContent, newSecondary) {

		if ( showingNewContent ) {

			if ( newSecondary ) {
				this.resetSecondary(true);
			}
			this.resetSecondaryContent();
			this.currentSecondary.classList.add('is-active');

			setTimeout(function() {
				this.currentSecondary.classList.add('is-animating');
				this.currentSecondaryContent.classList.add('is-active');
				var height = Utils.getHeight(this.currentSecondaryContent);

				this.currentSecondary.setAttribute("style","height:"+height+"px;");

				setTimeout(function() {
					this.currentSecondaryContent.classList.add('is-animating');
				}.bind(this), 50);

			}.bind(this), 50);

			this.currentEntryButton.setAttribute('aria-expanded', 'true');
		}
		else {
			this.resetSecondary(true,true);
			this.resetSecondaryContent(true);

			this.oldEntryId = -1;
			this.currentEntryId = -1;
			this.oldSecondaryId = -1;
			this.currentSecondaryId = -1;
			this.currentSecondaryContentId = -1;
		}
	}

	resizeHandler() {

		var width = window.innerWidth;
		if ( this.savedWindowWidth === width ) return false;
		else {
			this.savedWindowWidth = width;
			this.measureCols();
		}
	}

	measureCols(){
		var width = window.outerWidth;
		if ( typeof width == "undefined" || width <= 0) width = window.innerWidth;

		//Log.db("measureCols | width="+width)
		if ( width < 768 ) {
			//mobile landscape
			this.rearrangeEntries(1);
		}
		else if ( width < 1024) {
			//tablet portrait
			this.rearrangeEntries(2);
		}
		else {
			//tablet landscape + desktop
			this.rearrangeEntries(3);
		}
	}

	rearrangeEntries(cols) {
		// Log.fn("EntriesNavigation | rearrangeEntries");
	}

	secondaryCloseHandler() {
		this.controlSecondaryNavigation(false);
	}

}

export default EntriesNavigation;
