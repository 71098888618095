import Emitter from './emitter';
import Utils from './utils';

import KeywordEdit from './keyword-edit';
import KeywordToggle from './keyword-toggle';
import Overlay from './overlay';


class ModalBox {

	constructor(settings) {
		this.className = 'modal';


		let defaultTemplateContent = document.querySelector('#modalTemplate').innerHTML;

		this.el = document.createElement('div');
		this.el.className = this.className+' '+settings.cssClasses;

		this.el.innerHTML = defaultTemplateContent;
		this.el.querySelector('.modal__content').innerHTML = settings.content;

		this.overlay = new Overlay({ isFixed: true, fixBody: settings.fixBody });

		this.isActive = false;


		this.render();
	}

	render() {

		this.overlay.render();
		this.overlay.append(this.el);

		[...this.el.querySelectorAll('.js--close-modal')].map((elem)=>{
			elem.addEventListener('click', this.onClose.bind(this));
		});

		Emitter.trigger('modalbox:rendered');

		this.toggle();

		// -------------

		[...this.el.querySelectorAll('.js--keyword-toggle')].map((elem)=>{
			new KeywordToggle(elem);
		});
		[...this.el.querySelectorAll('.js--keyword-edit')].map((elem)=>{
			new KeywordEdit(elem);
		});

	}

	onClose(e) {
		e.stopPropagation();

		Emitter.trigger('modal:close');
		this.overlay.hide();

		// Utils.remove(this.el);
	}

	handleEvents(e){
		if(!this.isActive) { return; }

		if(e.target === this.overlay || this.settings.isGallery) {
			this.removeWithTransitions();
		}
		if(e.keyCode === this.settings.esc) {
			this.removeWithTransitions();
		}

	}

	removeHandler() {
		Utils.remove(this.el);
	}

	toggle(handle){
		this.isActive = !this.isActive;
	}
	get eventEmitter() {
		return Emitter;
	}

}


export default ModalBox;
