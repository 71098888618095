class KeywordToggle {
	constructor(el) {
		this.el = el;
		this.el.addEventListener('click', this.onToggle.bind(this));
	}

	onToggle() {
		this.el.classList.toggle('is-active');
	}
}

export default KeywordToggle;
