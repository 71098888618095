class SiteFooter {
	constructor(el) {
		this.el = el;

		this.decks = document.querySelectorAll('.deck');
		this.deckCount = this.decks.length;

		this.lastDeck = this.decks[this.deckCount-1];

		let noColor = !this.lastDeck.classList.contains('deck--theme');

		if ( noColor ) {
			this.el.classList.add('site-footer--border');
		}
	}
}

export default SiteFooter;
