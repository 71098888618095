import Emitter from './emitter';
import Accordion from './accordion';
import Utils from './utils';
import View from '../view';
class AccordionController extends View {

	constructor(el){
		super(el);
		if(this.el.getAttribute('data-mounted')) return;
		this.el.setAttribute('data-mounted', true);
		this.autoOpen = false;
		this.allOpen = false;
		var wW = window.innerWidth;
		this.uuid = Utils.uniqueId(4);
		
		var dataAutoOpen = this.el.dataset.autoopen;

		if ( dataAutoOpen ) {
			if ( dataAutoOpen == "desktop" && wW > 767 ) {
				this.autoOpen = true;
			}
			else if ( dataAutoOpen == "mobile"  && window.innerWidth < 768) {
				this.autoOpen = true;
			}
		}

		let accordions = this.el.querySelectorAll('.js--accordion');
			[...accordions].map((elem)=> {
				const accordion = new Accordion(elem, this.uuid);
			});

		this.trigger = this.el.querySelector('.js--accordion-controller-trigger');
		this.trigger && this.trigger.addEventListener('click', this.onToggleAll.bind(this));

		if ( this.autoOpen ) setTimeout(function(){
			this.onToggleAll(null);
		}.bind(this), 200);
	}

	onToggleAll(e){
		try {
			e.preventDefault();
			e.stopPropagation();
		} catch (error) { }
		this.allOpen = !this.allOpen;
		Emitter.trigger(this.allOpen ? 'accordion:open' : 'accordion:close', { id: this.uuid });
	}
}

export default AccordionController;
