import Emitter from "./emitter";
import Utils from "./utils";
import View from "../view";
import { TweenLite } from "gsap";

class Reveal extends View {
	get getInitialOptions() {
		return {
			allowMultiple: true,
			allowToggle: true,
			duration: 0.3
		};
	}
	constructor(el, id = null) {
		super(el);

		this.id = id;
		if (this.el.getAttribute("data-mounted")) return;
		this.options = this.getInitialOptions;
		this.el.setAttribute("data-mounted", true);

		this.open = this.open.bind(this);
		this.close = this.close.bind(this);

		for (let key in this.el.dataset) {
			if (this.options.hasOwnProperty(key)) {
				this.options[key] =
					this.el.dataset[key] === "true" ? true : false;
			}
		}

		this.trigger = this.el.querySelector(".js--reveal-trigger");
		this.trigger.addEventListener("click", this.onToggle.bind(this));

		this.savedWindowWidth = 0;

		this.content = this.el.querySelector(".js--reveal-content");

		// no specific height on content, if lower than 500 characters
		if (this.content.innerText.length < 500) {
			this.el.classList.add("remove-read-more")
		}
	}

	onToggle(e) {
		e.preventDefault();
		e.stopPropagation();
	
		// Check if the current toggle is expanded.
		const isOpen = this.trigger.getAttribute("aria-expanded") == "true";

		if (!isOpen) {
			this.open();

		// "vis mere / vis mindre"
		if (this.el.dataset.revealedText && this.el.dataset.hiddenText) {
			this.trigger.innerText = this.el.dataset.revealedText;
		}
		} else if (
			this.options.allowToggle ||
			(this.options.allowMultiple && isOpen)
		) {
			this.close();

			// "vis mere / vis mindre"
			if (this.el.dataset.revealedText && this.el.dataset.hiddenText) {
				this.trigger.innerText = this.el.dataset.hiddenText;
			}
		}
	}

	open() {
		this.el.classList.add("is-active");
		this.content.style.height = "auto";

		TweenLite.from(this.content, this.options.duration, {
			height: "6em",
			onComplete: () => {
				// Set the expanded state on the triggering element
				this.trigger.setAttribute("aria-expanded", "true");

				// If toggling is not allowed, set disabled state on trigger
				if (!this.options.allowToggle) {
					this.trigger.setAttribute("aria-disabled", "true");
				}
			}
		});
		setTimeout(
			function() {
				Emitter.trigger("panel:update");
			}.bind(this),
			500
		);
	}

	close() {
		// Set the expanded state on the triggering element
		this.el.classList.remove("is-active");

		TweenLite.to(this.content, this.options.duration, {
			height: "6em",
			onComplete: () => {
				this.trigger.setAttribute("aria-expanded", "false");
				// When toggling is not allowed, clean up disabled state
				if (!this.options.allowToggle) {
					this.trigger.removeAttribute("aria-disabled");
				}
				setTimeout(
					function() {
						Emitter.trigger("panel:update");
					}.bind(this),
					500
				);
			}
		});
	}

	resizeHandler(event) {
		var width = window.outerWidth;
		if (this.savedWindowWidth === width) return false;
		else {
			this.savedWindowWidth = width;
			this.updateHeight();
		}
	}
	updateHeight(event) {
		return;
	}
	static eventEmitter() {
		return Emitter;
	}
}

export default Reveal;
