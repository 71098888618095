import Emitter from './emitter';

class CriterionList {

	constructor(el) {
		this.el = el;

		this.labelId = this.el.dataset.labelid;

		if ( this.labelId ) this.countLabelRef = document.querySelector('#'+this.labelId);

		Emitter.trigger('criterionlist:init', this.instanceId);
		Emitter.on('criterionlist:clear', this.clearAll.bind(this));

		this.criterionElements = this.el.querySelectorAll('.js--criterion');

		this.criteria = this.el.querySelectorAll('.js--criterion');
		[...this.criteria].map((criterion)=>{
			criterion.addEventListener('click',this.toggleCriterion.bind(this));
		});

	}

	toggleCriterion(event) {
		let criterion = event.currentTarget;
		criterion.classList.toggle('is-active');
		let active = criterion.classList.contains('is-active');
		criterion.setAttribute('aria-checked', active);

		this.countActives();
	}

	countActives(argument) {
		let activeCount = this.el.querySelectorAll('a.is-active').length;
		if ( activeCount > 0 ) {
			Emitter.trigger('criterionlist:actives', this.instanceId);
			if ( this.countLabelRef ) {
				this.countLabelRef.textContent = activeCount + " valgt";
				this.countLabelRef.classList.add('is-active');
			}
		}
		else {
			Emitter.trigger('criterionlist:empty', this.instanceId);
			if ( this.countLabelRef ) {
				this.countLabelRef.classList.remove('is-active');
			}
		}
	}

	clearAll() {
		[...this.criteria].map((criterion)=>{
			criterion.classList.remove('is-active');
		});
	}

}

export default CriterionList;
